"use client";
import { toggleCartModalOpen } from "@/redux/features/cartModalSlice";
import { toggleSearchModalOpen } from "@/redux/features/searchModalSlice";
import { toggleWishlistModalOpen } from "@/redux/features/wishlistModalSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import ServerDataProp from "@/types/ServerDataProp";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import NavSection from "../navigation/NavSection";

const HeaderSection2: React.FC<ServerDataProp> = ({ siteSetting }) => {
  const dispatch = useAppDispatch();

  const [totalCartItems, setTotalCartItems] = useState(0);
  const cartItems = useAppSelector((state) => state.cart.cart);
  const [totalWishlistItems, setTotalWishlistItems] = useState(0);
  const wishlistItems = useAppSelector((state) => state.wishlist.wishlist);

  const openSearchModal = () => {
    dispatch(toggleSearchModalOpen());
  };

  const openCartModal = () => {
    dispatch(toggleCartModalOpen());
  };

  const openWishlistModal = () => {
    dispatch(toggleWishlistModalOpen());
  };

  useEffect(() => {
    // Calculate total quantity of items in the cart
    const total = cartItems.reduce(
      (total, currentItem) => total + currentItem.quantity,
      0
    );
    setTotalCartItems(total);
  }, [cartItems]);
  useEffect(() => {
    // Calculate total quantity of items in the cart
    const total = wishlistItems.reduce(
      (total, currentItem) => total + currentItem.quantity,
      0
    );
    setTotalWishlistItems(total);
  }, [wishlistItems]);

  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const openSidebar = () => {
    setIsSidebarOpen(true);
    setIsHeaderFixed(false);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200 && !isSidebarOpen) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200 && !isSidebarOpen) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeSidebar]);
  return (
    <header
      className={`rv-12-header rv-1-header to-be-fixed ${isHeaderFixed ? "fixed" : ""
        }`}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-3 col-5" style={{ display: 'flex' }}>
            <button
              className="rv-1-header-mobile-menu-btn rv-3-def-btn rv-12-mobile-menu-btn d-inline-block d-lg-none"
              id="rv-1-header-mobile-menu-btn"
              style={{ margin: "8px" }}
              onClick={openSidebar}
            >
              <i className="fa-regular fa-bars"></i>
            </button>
            <div className="rv-8-header__logo">
              <Link href="/">
                <img src="/assets/img/logo.png" alt="XanhEco" />
              </Link>
            </div>
          </div>

          <div className="col-lg-6 order-lg-1 order-2">
            <div
              className={`rv-1-header-nav__sidebar ${isSidebarOpen ? "active" : ""}`}
              ref={sidebarRef}
            >
              <div className="sidebar-heading d-lg-none d-flex align-items-center justify-content-between">
                <Link href="/" className="logo-container">
                  <img src="/assets/img/logo.png" alt="XanhEco" />
                </Link>
                <button
                  className="rv-3-def-btn rv-1-header-mobile-menu-btn rv-12-mobile-menu-btn sidebar-close-btn"
                  onClick={closeSidebar}
                >
                  <i className="fa-regular fa-xmark"></i>
                </button>
              </div>

              <NavSection style="rv-12-header__nav" />
            </div>
          </div>

          <div className="col-lg-3 col-sm-9 col-7 order-lg-2 order-1">
            <div className="rv-5-header__actions rv-12-header__actions">
              <button
                className="rv-12-header__search-btn rv-search-modal-open-btn"
                onClick={openSearchModal}
              >
                <i className="fa-regular fa-magnifying-glass"></i>
              </button>
              <button
                className="rv-5-cart-btn rv-11-wishlist-btn d-block"
                onClick={openWishlistModal}
              >
                <i className="fa-regular fa-heart"></i>
                <span className="quantity">{totalWishlistItems}</span>
              </button>
              <button
                className="rv-5-cart-btn d-block"
                onClick={openCartModal}
              >
                <i className="fa-regular fa-bag-shopping"></i>
                <span className="quantity">{totalCartItems}</span>
              </button>
              {/* <Link href="/sign-in" className="rv-12-header-login  d-xxs-none">
                <i className="rv-5-cart-btn">
                  <i className="fa-regular fa-user"></i>
                </i>
                <span className="rv-12-header-login__txt text-start">
                  <span>Xin chào</span>
                  <span className="bottom">Đăng nhập</span>
                </span>
              </Link> */}
              {/* <button
                className="rv-1-header-mobile-menu-btn rv-3-def-btn rv-12-mobile-menu-btn d-inline-block d-lg-none"
                id="rv-1-header-mobile-menu-btn"
                onClick={openSidebar}
              >
                <i className="fa-regular fa-bars"></i>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderSection2;
