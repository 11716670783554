"use client";
import { toggleSearchModalClose } from "@/redux/features/searchModalSlice";
import { useAppDispatch } from "@/redux/hooks";
import { navigate } from "@/services/ui/useRedirect";
import { SubmitHandler, useForm } from "react-hook-form";
type Input = {
  text: string;
};

const SearchForm = () => {
  const { register, handleSubmit, reset } = useForm<Input>();
  const dispatch = useAppDispatch();
  const onSubmit: SubmitHandler<Input> = (data) => {
    // Perform any additional actions before or after submitting data
    console.log(data);

    navigate(`/blog?search=${encodeURIComponent(data.text)}`)
    // Reset the form to default values
    reset();
    dispatch(toggleSearchModalClose());
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="search"
        id="rv-search-modal-input"
        placeholder="Search Here"
        required
        {...register("text")}
      />
      <button type="submit">
        <i className="fa-regular fa-magnifying-glass"></i>
      </button>
    </form>
  );
};

export default SearchForm;
