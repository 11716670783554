export const LOAD_BANNER="/web.load_banner";
export const LOAD_PRODUCT="/web.load_product";
export const LOAD_SECTION_SERVICE="/web.load_section_service"
export const LOAD_PORTFOLIO="/web.load_portfolio";
export const LOAD_TEAM_MEMBER="/web.load_team_member";
export const LOAD_BLOG = "/web.load_blog";
export const LOAD_WEB_SETTING="/web.load_config";
export const LOAD_PAGE_SETTING="/web.load_page";
export const LOAD_DETAIL_METADATA="/web.get_detail_metadata";

export const LOAD_TESTIMONIAL_DATA="/web.load_testimonial";
export const LOAD_CATEGORY="/web.load_category";
export const LOAD_GALLERY="/web.load_gallery";
export const LOAD_PARTNER="/web.load_partner";
export const LOAD_FAQ="/web.load_faq";
export const LOAD_PROJECT = "/web.load_project";
export const LOAD_FEATURE = "/web.load_feature";
export const LOAD_BLOG_CATEGORY="/web.load_blog_category";
export const LOAD_RELATE_TAGS="/web.load_relate_tags";

export const LOAD_COMMENT="/web.load_comment";
//----------------
export const GET_PRODUCT_DETAIL="/web.get_product_detail";
export const GET_BLOG_DETAIL="/web.get_blog_detail";
export const GET_PROJECT_DETAIL="/web.get_project_detail";
//------------------------
export const POST_CONTACT_FORM="/crm.submit_contact_form";
export const POST_SUBSCIRBE_EMAIL="/crm.subscribe_email";
export const POST_SUBMIT_ORDER="/crm.create_order";
export const POST_COMMENT = "/web.create_comment";