"use client"
import { POST_SUBSCIRBE_EMAIL } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import ServerDataProp from "@/types/ServerDataProp";
import Link from "next/link";
import React from "react";
import { toast } from "react-toastify";

const FooterSection2: React.FC<ServerDataProp> = ({ siteSetting, setting }) => {

  function sendForm(formData: FormData) {
    const params = {
      email: formData.get("email")
    }
    callApi.post(POST_SUBSCIRBE_EMAIL, params).then(res => {
      toast.success("Yêu cầu đăng ký thành công")
    })
  }
  return (
    <footer className="rv-1-footer rv-3-footer rv-12-footer rv-12-infos p-0">
      <div className="container">
        <div className="rv-1-footer-top">
          <div className="row gy-5 justify-content-xl-between justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="rv-1-footer__about">
                <Link href="/">
                  <img
                    src="/assets/img/logo.png"
                    alt="Logo"
                    className="logo"
                  />
                </Link>
                <p className="rv-1-footer__about-txt">
                  {siteSetting?.aboutus}
                </p>
                <div className="rv-1-socials rv-12-socials">

                  {
                    siteSetting?.facebook && <a href={siteSetting?.facebook}>
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  }
                  {
                    siteSetting?.zalo && <a href={siteSetting?.zalo}>
                      <img width={32} src="/assets/img/icons8-zalo.svg" />
                    </a>
                  }
                  {
                    siteSetting?.pinterest && <a href={siteSetting?.pinterest}>
                      <i className="fa-brands fa-pinterest-p"></i>
                    </a>
                  }
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-8 order-1 order-md-2 order-lg-1">
              <div className="row gy-4">
                {
                  siteSetting?.footersection && siteSetting.footersection.map((section, ind) => <div key={ind} className="col-md-4 col-6 col-xxs-12">
                    <div className="rv-1-footer-widget rv-12-footer-widget">
                      <h5 className="rv-1-footer-widget__title">{section.name}</h5>
                      <ul className="rv-1-footer-widget__links">
                        {
                          section.items && section.items.map((item, index) => <li key={index}>
                            <Link href={item.url}>{item.name}</Link>
                          </li>)
                        }

                      </ul>
                    </div>
                  </div>)
                }


                <div className="col-md-4 col-6 col-xxs-12">
                  <div className="rv-1-footer-widget rv-12-footer-widget">
                    <h5 className="rv-1-footer-widget__title">Liên hệ</h5>
                    <ul className="rv-1-footer-widget__infos">
                      <li>
                        <img src="/assets/img/rv-1-icon-6.png" alt="icon" /> {siteSetting?.address}
                      </li>
                      <li>
                        <img src="/assets/img/rv-1-icon-7.png" alt="icon" />{" "}
                        <a href={`tel:${siteSetting?.tel}`}>{siteSetting?.tel}</a>
                      </li>
                      <li>
                        <img src="/assets/img/rv-1-icon-8.png" alt="icon" />{" "}
                        <a href={`mailto:${siteSetting?.email}`}>{siteSetting?.email}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 order-2 order-md-1 order-lg-2">
              <div className="rv-1-footer-nwsltr rv-3-footer-nwsltr">
                <h5 className="rv-1-footer-widget__title">Nhận thông báo</h5>
                <form
                  action={sendForm}
                  className="rv-1-footer-nwsltr__form rv-3-footer-nwsltr__form rv-12-footer-nwsltr__form"
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Nhập địa chỉ thư..."
                    required
                    autoComplete="true"
                  />
                  <button className="rv-1-def-btn">
                    <span className="txt">Đăng ký</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="rv-1-footer-bottom">
          <div className="row gy-3">
            <div className="col-md-8">
              <p className="rv-1-copyright m-0 text-center text-md-start">
                &copy; {new Date().getFullYear()} by XanhEco
              </p>
            </div>

            {/* <div className="col-md-4 text-center text-md-end">
              <img
                src="assets/img/payment_method.png"
                alt="Payment Method image"
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="rv-12-infos__vectors">
        <img
          src="assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
      </div>
      <div className="rv-12-infos__vectors rv-12-infos__vectors--2">
        <img
          src="assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
      </div>
    </footer>
  );
};

export default FooterSection2;
