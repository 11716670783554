
import axios, { AxiosRequestConfig } from "axios";
import i18next from "i18next";
import { navigate } from "../ui/useRedirect";

const buildUrlWithQuery = (url: string, newParam: string) => {
  const urlObj = new URL(url); // Tạo đối tượng URL
  const searchParams = new URLSearchParams(urlObj.search); // Lấy query string hiện tại
  const decodeParam = decodeURIComponent(newParam);

  const [key, value] = decodeParam.split('='); // Tách tham số mới thành key và value

  searchParams.set(key, value); // Cập nhật hoặc thêm tham số mới
  urlObj.search = searchParams.toString(); // Gắn lại query string

  return urlObj.toString(); // Trả về URL chuẩn tắc
};


export class serverSideApi {
  static post = async function (
    path: string,
    data: any,
    config?: AxiosRequestConfig<any> | undefined,
  ): Promise<any> {
    var url = process.env.NEXT_PUBLIC_API + path;
    if (process.env.NEXT_PUBLIC_EXTRA_QUERY) {
      url = buildUrlWithQuery(url, process.env.NEXT_PUBLIC_EXTRA_QUERY);
    }

    try {
      var response = await axios.post(url, data, config);
      
      if (response?.data?.code != 0) {
        return {
          code: response?.data?.code,
          data: response.data?.data ?? response.data,
          message: response.data?.message,
          records: response?.data?.records,
          dataTotal: response?.data?.dataTotal,
        };
      }

      return {
        code: 0,
        data: response?.data?.data ?? response.data,
        message: response?.data?.message,
        records: response?.data?.records,
        dataTotal: response?.data?.dataTotal,
      };
    } catch (error: any) {
      return {
        code: 1,
        message: error.message,
        data: error.message,
      };
    }
  };
  static get = async function (
    path: string,
    config?: AxiosRequestConfig<any> | undefined,
  ): Promise<any> {
    var url = process.env.NEXT_PUBLIC_API + path;
    if (process.env.NEXT_PUBLIC_EXTRA_QUERY) {
      url = buildUrlWithQuery(url, process.env.NEXT_PUBLIC_EXTRA_QUERY);
    }

    try {
      var response = await axios.get(url, config);
      
      if (response?.data?.code != 0) {
        return {
          code: response?.data?.code,
          data: response.data?.data ?? response.data,
          message: response.data?.message,
          records: response?.data?.records,
        };
      }
      return {
        ...response.data,
        code: 0,
        data: response?.data?.data ?? response.data,
        message: response?.data?.message,
        records: response?.data?.records,
      };
    } catch (error: any) {
      return {
        code: 1,
        message: error.message,
        data: error.message,
      };
    }
  };

}

export class callApi {
  static post = async function (
    path: string,
    data: any,
    config?: AxiosRequestConfig<any> | undefined,
  ): Promise<any> {
    var url = process.env.NEXT_PUBLIC_API + path;
    if (process.env.NEXT_PUBLIC_EXTRA_QUERY) {
      url = buildUrlWithQuery(url, process.env.NEXT_PUBLIC_EXTRA_QUERY);
    }
    const token =
      sessionStorage.getItem("access_token") ??
      localStorage.getItem("access_token");
    var headers = {
      Lang: i18next.language == "en" ? "en_US" : "vi_VN",
      Authorization: token ? `Bearer ${token}` : null,
    };
    try {
      var response = await axios.post(url, data, { headers, ...config });
      if (response?.data?.code == 2) {
        navigate("/signin");
        // successToast(i18next.t("error"));
      }

      if (response?.data?.code != 0) {
        return {
          code: response?.data?.code,
          data: response.data?.data ?? response.data,
          message: response.data?.message,
          records: response?.data?.records,
          dataTotal: response?.data?.dataTotal,
        };
      }

      return {
        code: 0,
        data: response?.data?.data ?? response.data,
        message: response?.data?.message,
        records: response?.data?.records,
        dataTotal: response?.data?.dataTotal,
      };
    } catch (error: any) {
      return {
        code: 1,
        message: error.message,
        data: error.message,
      };
    }
  };
  static get = async function (
    path: string,
    config?: AxiosRequestConfig<any> | undefined,
  ): Promise<any> {
    var url = process.env.NEXT_PUBLIC_API + path;
    if (process.env.NEXT_PUBLIC_EXTRA_QUERY) {
      url = buildUrlWithQuery(url, process.env.NEXT_PUBLIC_EXTRA_QUERY);
    }
    const token =
      sessionStorage.getItem("access_token") ??
      localStorage.getItem("access_token");
    var headers = {
      Lang: i18next.language == "en" ? "en_US" : "vi_VN",
      Authorization: token ? `Bearer ${token}` : null,
    };

    try {
      var response = await axios.get(url, { headers, ...config });
      if (response?.data?.code == 2) {
        navigate("/signin");
        // successToast(i18next.t("error"));
      }
      if (response?.data?.code != 0) {
        return {
          code: response?.data?.code,
          data: response.data?.data ?? response.data,
          message: response.data?.message,
          records: response?.data?.records,
        };
      }
      return {
        ...response.data,
        code: 0,
        data: response?.data?.data ?? response.data,
        message: response?.data?.message,
        records: response?.data?.records,
      };
    } catch (error: any) {
      return {
        code: 1,
        message: error.message,
        data: error.message,
      };
    }
  };

  static upload = async function (
    url: string,
    data: any,
    config?: AxiosRequestConfig<any> | undefined,
  ) {
    const token =
      sessionStorage.getItem("access_token") ??
      localStorage.getItem("access_token");
    var headers = {
      Lang: i18next.language == "en" ? "en_US" : "vi_VN",
      Authorization: token ? `Bearer ${token}` : null,
    };
    try {
      var response = await axios.post(url, data, { headers, ...config });
      return {
        code: 0,
        data: response?.data,
        message: response?.status,
      };
    } catch (e: any) {
      return {
        code: 1,
        data: e.message,
        message: e.message,
      };
    }
  };
}
