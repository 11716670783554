"use client";
import Link from "next/link";
import { useState } from "react";
type Props = {
  style: string;
};
type DropdownState = {
  home: boolean;
  pages: boolean;
  shop: boolean;
  blog: boolean;
};
const NavSection = ({ style }: Props) => {
  const [dropdown, setDropdown] = useState<DropdownState>({
    home: false,
    pages: false,
    shop: false,
    blog: false,
  });

  const handleToggleDropdown = (dropdownName: keyof DropdownState) => {
    if (window.innerWidth < 992) {
      setDropdown((prevState) => ({
        ...prevState,
        [dropdownName]: !prevState[dropdownName],
      }));
    }
  };

  return (
    <div className={`rv-1-header__nav ${style}`}>
      <ul className="justify-content-center">
        {/* <li className={dropdown.home ? "rv-dropdown-active" : ""}>
          <a role="button" onClick={() => handleToggleDropdown("home")}>
            Trang chủ
          </a>

          <ul className="sub-menu">
            <li>
              <Link href="/home-1">Làm vườn</Link>
            </li>
            <li>
              <Link href="/home-2">Trà Herbal</Link>
            </li>
            <li>
              <Link href="/home-3">Công nghệ xanh</Link>
            </li>
          </ul>
        </li> */}
        <li>
          <Link href="/">Trang chủ</Link>
        </li>
        <li>
          <Link href="/about">Về chúng tôi</Link>
        </li>


        <li>
          <Link href="/shop">Sản phẩm</Link>
        </li>
        {/* <li className={dropdown.shop ? "rv-dropdown-active" : ""}>
          <a role="button" onClick={() => handleToggleDropdown("shop")}>
            Sản phẩm
          </a>
          <ul className="sub-menu">
            <li>
              <Link href="/shop">Sản phẩm</Link>
            </li>
            <li>
              <Link href="/shop-sidebar">Sản phẩm (Sidebar)</Link>
            </li>
            <li>
              <Link href="/shop/herbal-hair-oil">Sản phẩm Chi tiết</Link>
            </li>
          </ul>
        </li> */}
        <li className={dropdown.blog ? "rv-dropdown-active" : ""}>
          <a role="button" onClick={() => handleToggleDropdown("blog")}>
            Tin tức & Dự án
          </a>
          <ul className="sub-menu">
            <li>
              <Link href="/blog">Tin tức</Link>
            </li>
            <li>
              <Link href="/projects">Dự án</Link>
            </li>            
          </ul>
        </li>
        {/* <li>
          <Link href="/blog">Blogs</Link>
        </li> */}
        {/* <li className={dropdown.pages ? "rv-dropdown-active" : ""}>
          <a role="button" onClick={() => handleToggleDropdown("pages")}>
            Hoạt động
          </a>
          <ul className="sub-menu">
            <li>
              <Link href="/services">Dịch vụ</Link>
            </li>
            <li>
              <Link href="/services/web-solution">Chi tiết dịch vụ</Link>
            </li>
            <li>
              <Link href="/projects">Dự án</Link>
            </li>
            <li>
              <Link href="/projects/sustainable-planting-drive">
                Chi tiết dự án
              </Link>
            </li>
            <li>
              <Link href="/team">Thành viên</Link>
            </li>
            <li>
              <Link href="/sign-in">Đăng nhập</Link>
            </li>
            <li>
              <Link href="/sign-up">Đăng ký</Link>
            </li>
            <li>
              <Link href="/cart">Giỏ hàng</Link>
            </li>
            <li>
              <Link href="/wishlist">Wishlist</Link>
            </li>
            <li>
              <Link href="/checkout">Checkout</Link>
            </li>
          </ul>
        </li> */}
        {/* <li className={dropdown.blog ? "rv-dropdown-active" : ""}>
          <a role="button" onClick={() => handleToggleDropdown("blog")}>
            Blog
          </a>
          <ul className="sub-menu">
            <li>
              <Link href="/blog">Blogs</Link>
            </li>
            <li>
              <Link href="/blog/finding-creative-flow-organic">
                Chi tiết Blog
              </Link>
            </li>
          </ul>
        </li> */}

        <li>
          <Link href="/contact">Liên hệ</Link>
        </li>
      </ul>
    </div>
  );
};

export default NavSection;
