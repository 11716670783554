import { ShopItem } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface WishlistState {
  wishlist: ShopItem[];
}

const initialState: WishlistState = {
  wishlist: [],
};
function handleSaveStorage(wishlist: ShopItem[]) {
  if (typeof window !== 'undefined') {
    if (wishlist)
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
    else localStorage.removeItem('wishlist');
  }
}
function handleGetStorage(): ShopItem[] {
  if (typeof window !== 'undefined') {
    const items: ShopItem[] = JSON.parse(localStorage.getItem("wishlist") || '[]');
    // console.log({items})
    return items || [];
  } else {
    return []
  }
  return []
}
const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: { wishlist: handleGetStorage() },
  reducers: {
    addToWishlist: (state, action: PayloadAction<ShopItem>) => {
      const existingItemIndex = state.wishlist.findIndex(
        (wishlistItem) => wishlistItem._id === action.payload._id
      );

      if (existingItemIndex === -1) {
        // Item doesn't exist in the wishlist, add it
        state.wishlist.push(action.payload);
      }

      handleSaveStorage(state.wishlist)
    },
    removeFromWishlist: (state, action: PayloadAction<string>) => {
      // Remove item from wishlist by id
      state.wishlist = state.wishlist.filter(
        (item) => item._id !== action.payload
      );

      handleSaveStorage(state.wishlist)
    },
    moveAllToCart: (state) => {
      // Clear wishlist
      state.wishlist = [];

      handleSaveStorage(state.wishlist)
    },
  },
});

export const { addToWishlist, removeFromWishlist, moveAllToCart } =
  wishlistSlice.actions;

export const selectWishlistState = (state: RootState) => state.wishlist;

export default wishlistSlice.reducer;
