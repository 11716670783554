import { ShopItem } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface CartState {
  cart: ShopItem[];
}

const initialState: CartState = {
  cart: [
 
  ],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<ShopItem>) => {
      const existingItemIndex = state.cart.findIndex(
        (cartItem) => cartItem._id === action.payload._id
      );

      if (existingItemIndex !== -1) {
        // Item already exists in the cart, update quantity
        state.cart[existingItemIndex].quantity += action.payload.quantity; // Update quantity by adding the quantity provided in the action payload
      } else {
        // Item doesn't exist in the cart, add it
        state.cart.push(action.payload);
      }
    },

    removeFromCart: (state, action: PayloadAction<string>) => {
      // Remove item from cart by id
      state.cart = state.cart.filter((item) => item._id !== action.payload);
    },
    removeAllCart:(state)=>{
      //xoa toan bo gio hang sau khi dat hang hoan thanh
      state.cart=[];
    },
    updateQuantity: (
      state,
      action: PayloadAction<{ id: string; quantity: number }>
    ) => {
      const { id, quantity } = action.payload;
      const cartItem = state.cart.find((item) => item._id === id);

      if (cartItem) {
        // Ensure the quantity is greater than 1 before updating
        cartItem.quantity = Math.max(1, quantity);
      }
    },
  },
});

export const { addToCart, removeFromCart, updateQuantity,removeAllCart } = cartSlice.actions;

export const selectCartState = (state: RootState) => state.cart;

export default cartSlice.reducer;
